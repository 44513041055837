import React from 'react'
import { Box, Button } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, staffsAtom, tokenAtom } from '../actions';

const logo_image = require('../assets/images/logo_icon.png')

const menu = {
    'crm': {
        permissions: ['crm_access'],
        items: [
            { key: '1', name: 'Báo cáo', path: '/crm/report', permissions: ['view_report', 'view_report_all'] },
            { key: '2', name: 'Lead', path: '/crm/leads', permissions: ['create_new_lead', 'create_exist_lead', 'view_lead', 'view_lead_all', 'write_lead_all'] },
            // { key: '3', name: 'Deal', path: '/crm/deals' },
            { key: '4', name: 'Nguồn khách', path: '/crm/groups', permissions: ['view_group', 'view_group_all'] },
            { key: '5', name: 'Khách hàng', path: '/crm/contacts', permissions: ['view_customer', 'view_customer_all'] },
            { key: '6', name: 'Đơn hàng', path: '/crm/orders', permissions: ['view_order', 'view_order_all'] },
            // { key: '6', name: 'Báo giá', path: '/crm/quotes' },
            { key: '7', name: 'Thanh toán', path: '/crm/payments', permissions: ['view_payment', 'view_payment_all', 'write_payment'] },
            { key: '8', name: 'Hoa hồng', path: '/crm/comissions', permissions: ['view_comission', 'view_comission_all', 'write_comission'] },
            // { key: '8', name: 'Công việc', path: '/crm/tasks' },
        ]
    },
    // 'marketing': [
    //     { key: '9', name: 'Chiến dịch', path: '/marketing/campaigns' },
    //     { key: '10', name: 'Mã giảm giá', path: '/marketing/vouchers' },
    //     { key: '11', name: 'SMS', path: '/marketing/sms-history' }
    // ],
    // 'app': [
    //     { key: '12', name: 'Dịch vụ', path: '/app/services' },
    //     { key: '13', name: 'Nhân viên', path: '/app/staffs' },
    // ],
    'management': {
        permissions: ['management_access'],
        items: [
            // { key: '14', name: 'Báo cáo', path: '/management/reports' },
            { key: '15', name: 'Nhân viên', path: '/management/accounts', permissions: ['management_access'] },
            { key: '16', name: 'Phân quyền', path: '/management/permissions', permissions: ['management_access'] },
        ]
    },
}

function haveCommonObjects(array1, array2) {
    // Check if any object in array1 is present in array2
    return array1.some(obj1 => array2.some(obj2 => obj1 === obj2));
}

const SideBar = () => {
    const location = useLocation()
    const history = useHistory()
    const [auth] = useAtom(authAtom)

    if(location.pathname.indexOf(`/login`) >= 0) return null

    return (
        <Box sx={{ borderRight: '1px solid #aaaaaa', width: '100%', height: '100%', minHeight: '100vh', alignItems: 'center', display: 'flex', flexDirection: 'column', backgroundColor: '#ffd1dc' }}>
            <img src={logo_image} style={{ width: 80, height: 40, marginTop: 24, marginBottom: 16 }} alt='' />
            {
                Object.keys(menu).map((key) => {
                    if (location.pathname.indexOf(`/${key}/`) >= 0) {
                        const section = menu[key]
                        if (haveCommonObjects(auth.permissions ?? [], section.permissions ?? [])) {
                            return section.items.map((item) => {
                                if (haveCommonObjects(auth.permissions ?? [], item.permissions ?? [])) {
                                    return (
                                        <Button
                                            onClick={() => history.push(item.path)}
                                            sx={{
                                                height: 60, width: '100%', display: 'flex', alignItems: 'center',
                                                backgroundColor: location.pathname.indexOf(item.path) >= 0 ? '#ff000099' : 'transparent',
                                                color: location.pathname.indexOf(item.path) >= 0 ? 'white' : 'black',
                                                textAlign: 'left', justifyContent: 'flex-start'
                                            }}
                                            key={item.key}
                                        >
                                            {item.name}
                                        </Button>
                                    )
                                } else {
                                    return null
                                }

                            })
                        } else {
                            return null
                        }
                    }

                })
            }
        </Box>
    )
}

export default SideBar