import React, { useState } from 'react'
import { Stack, Box, Select, MenuItem, Button, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useAtom } from 'jotai'
import { authAtom, staffsAtom } from '../../../../actions'
import { LEAD_ORDER_STATUS, convertMoney, hasPermission } from '../../../../utils/utils'
import dayjs from 'dayjs'

const OrderItem = ({ changeOrderDate, onUpdateOrder, orders, payments, order_date, status, id, sale1, sale2, telesale, onChangeSale1, onChangeSale2, onChangeTelesale, comission, onCancelOrder, onAddPayment, onChangeComission }) => {
    const [auth] = useAtom(authAtom)
    const [staffs] = useAtom(staffsAtom)

    let totalPrice = 0

    for (const order of orders) {
        totalPrice += (order.price)
    }

    let totalPaid = 0

    for (const payment of payments) {
        totalPaid += (payment.amount)
    }

    const staffOptions = staffs.map((item) => ({ label: item.full_name, id: item.id }))
    staffOptions.unshift({ label: 'Không có', id: null })

    return (
        <Stack style={{ background: '#eeeeee', padding: '8px', borderRadius: 5 }}>
            <Stack style={{ flex: 1, fontSize: '13px' }}>
                <Stack sx={{ gap: '3px' }}>
                    <Typography sx={{ fontSize: '13px' }}>{`Đơn hàng số: #${id.toString().padStart(6, '0')}`}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Tổng giá tiền: ${convertMoney(totalPrice, true)}`}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Đã thanh toán: ${convertMoney(totalPaid, true)}`}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Thời gian đặt: ${dayjs(order_date).format('HH:mm DD/MM/YYYY')}`}
                    <span style={{marginLeft: '10px', color: 'blue', textDecorationLine: 'underline', cursor: 'pointer'}} onClick={() => changeOrderDate && changeOrderDate()}>Thay đổi</span>
                    </Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Trạng thái: ${LEAD_ORDER_STATUS[status.toString()]}`}</Typography>
                </Stack>
                <Stack>
                    <Typography sx={{ fontSize: '13px' }}>{`Hoa hồng:`}</Typography>
                    <Stack sx={{ fontSize: '13px', gap: '2px', background: '#dddddd', padding: '8px 8px', margin: '0px 8px', borderRadius: '5px' }}>
                        <Stack direction='row' alignItems='center' spacing='5px'>
                            <Typography sx={{ fontSize: '13px' }}>{`Sale 1:`}</Typography>
                            <Select disabled={!hasPermission(auth, ['write_lead_all'])} 
                                sx={{width: '150px', fontSize: '13px'}} variant='standard' 
                                value={sale1 ? sale1.id : null} onChange={(e) => onChangeSale1(e.target.value)}>
                                {staffOptions.map((item) => (
                                    <MenuItem value={item.id}>{item.label}</MenuItem>
                                ))}
                            </Select>
                            <Typography sx={{ fontSize: '13px' }}>{` - Hoa hồng: ${convertMoney(comission && comission.sale1 ? comission.sale1 ?? 0 : 0, true)}`}</Typography>
                        </Stack>
                        <Stack direction='row' alignItems='center' spacing='5px'>
                            <Typography sx={{ fontSize: '13px' }}>{`Sale 2:`}</Typography>
                            <Select disabled={!hasPermission(auth, ['write_lead_all'])} 
                                sx={{width: '150px', fontSize: '13px'}} variant='standard' 
                                value={sale2 ? sale2.id : null} onChange={(e) => onChangeSale2(e.target.value)}>
                                {staffOptions.map((item) => (
                                    <MenuItem value={item.id}>{item.label}</MenuItem>
                                ))}
                            </Select>
                            <Typography sx={{ fontSize: '13px' }}>{` - Hoa hồng: ${convertMoney(comission && comission.sale2 ? comission.sale2 ?? 0 : 0, true)}`}</Typography>
                        </Stack>
                        <Stack direction='row' alignItems='center' spacing='5px'>
                            <Typography sx={{ fontSize: '13px' }}>{`Telesale:`}</Typography>
                            <Select disabled={!hasPermission(auth, ['write_lead_all'])} 
                                sx={{width: '150px', fontSize: '13px'}} variant='standard' 
                                value={telesale ? telesale.id : null} onChange={(e) => onChangeTelesale(e.target.value)}>
                                {staffOptions.map((item) => (
                                    <MenuItem value={item.id}>{item.label}</MenuItem>
                                ))}
                            </Select>
                            <Typography sx={{ fontSize: '13px' }}>{` - Hoa hồng: ${convertMoney(comission && comission.telesale ? comission.telesale ?? 0 : 0, true)}`}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Typography sx={{ fontSize: '13px', marginTop: '8px' }}>{`Các liệu trình:`}</Typography>
                <Stack sx={{ marginTop: '5px' }}>
                    {orders.map((order, index) => {
                        return (
                            <Stack key={order.id.toString()} direction='row' style={{ background: '#dddddd', padding: '0px 8px', fontSize: '13px', borderRadius: 5, margin: '0px 8px', alignItems: 'center' }}>
                                <Box flex={1}>
                                    <p>{`Tên liệu trình: ${order.service_name ?? order.service.name}`}</p>
                                    <p>{`Giá tiền: ${convertMoney(order.price, true)}`}</p>
                                    <p>{`Số buổi: ${order.number_of_booking ?? ''}`}</p>
                                    {order.note && <p>{`Note: ${order.note ?? ''}`}</p>}
                                </Box>
                                <Button sx={{height: '30px'}} variant='outlined' onClick={() => onUpdateOrder && onUpdateOrder(order)}>Cập nhật</Button>
                            </Stack>
                        )
                    })}
                </Stack>

            </Stack>
            <Stack direction='row' justifyContent='center' spacing='32px' marginTop='16px'>
                {totalPaid === 0 && <Button onClick={() => onCancelOrder && onCancelOrder()} variant='contained' color='error'>Huỷ đơn</Button>}
                {totalPaid < totalPrice && <Button onClick={() => onAddPayment && onAddPayment()} variant='contained' color='success'>Thanh toán</Button>}
                {totalPaid >= totalPrice && hasPermission(auth, ['write_comission']) && <Button onClick={() => onChangeComission && onChangeComission()} variant='contained' color='success'>Thay đổi hoa hồng</Button>}
            </Stack>
        </Stack>
    )
}

export default OrderItem