import { ListItemText, Box, Button, FormControl, Input, InputLabel, MenuItem, Checkbox, Select, Stack, Table, TableBody, TableContainer, TableHead, TableSortLabel, TableRow, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ActionsBar from '../../../components/ActionsBar';
import Paper from '@mui/material/Paper'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles'
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, staffsAtom, tokenAtom } from '../../../actions';
import axios from 'axios';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { hasPermission, showConfirmationToast } from '../../../utils/utils';
import DateRangeModal from '../Payments/modals/DateRangeModal';
import StaffReportItem from './components/StaffReportItem';
import { visuallyHidden } from '@mui/utils'
import { MultiSelect } from "react-multi-select-component";
import { Download } from '@mui/icons-material';
import * as XLSX from 'xlsx'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const headCells = [
    {
        id: 'full_name',
        paddingRight: false,
        disablePadding: false,
        label: 'Nhân viên',
    },
    {
        id: 'total_lead',
        paddingRight: true,
        disablePadding: false,
        label: 'Lead được giao',
    },
    {
        id: 'completed_lead',
        paddingRight: true,
        disablePadding: false,
        label: 'Lead Hoàn thành',
    },
    {
        id: 'order',
        paddingRight: true,
        disablePadding: false,
        label: 'Đơn hàng',
    },
    {
        id: 'totalPrice',
        paddingRight: true,
        disablePadding: false,
        label: 'Doanh thu',
    },
    {
        id: 'message',
        paddingRight: true,
        disablePadding: false,
        label: 'Tương tác KH',
    },
    {
        id: 'comission',
        paddingRight: true,
        disablePadding: false,
        label: 'Hoa hồng',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.paddingRight ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={
                                {
                                    '&.MuiTableSortLabel-root': {
                                        color: 'white',
                                    },
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: 'white',
                                    },
                                    '&.Mui-active': {
                                        color: 'white',
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: 'white !important',
                                    },
                                }
                            }
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const Menu = (props) => {
    const { isAllSelected, options, ...menuProps } = props;

    return (
        <Select.Menu {...menuProps}>
            <MenuItem
                value="all"
                onClick={() => {
                    if (isAllSelected) {
                        menuProps.onClose();
                    } else {
                        menuProps.onChange(options.map((option) => option.id));
                    }
                }}
            >
                <Checkbox checked={isAllSelected} />
                <ListItemText primary="Tất cả" />
            </MenuItem>
            {props.children}
        </Select.Menu>
    );
}

const Screen = () => {
    const [total, setTotal] = useState(0)
    const [token] = useAtom(tokenAtom)
    const [staffReports, setStaffReports] = useState([])
    const [leadReports, setLeadReports] = useState([])
    const [selectedStaffs, setSelectedStaffs] = useState([])
    const [staffs] = useAtom(staffsAtom)
    const [dateRangeModal, setDateRangeModal] = useState(false)
    const [startDate, setStartDate] = useState(dayjs().subtract(30, 'days').format())
    const [endDate, setEndDate] = useState(dayjs().format())
    const authUser = useAtomValue(authAtom)
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('totalPrice');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const onChangeRange = (range) => {
        setStartDate(range.startDate)
        setEndDate(range.endDate)
        setDateRangeModal(false)
    }

    const getStaffReports = useCallback(() => {
        if (token) {
            axios.post('/accounts/reports/staff_reports/', {
                staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null,
                startDate: dayjs(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                endDate: dayjs(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
            }, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then((response) => {
                    if (response && response.data) {
                        setStaffReports(response.data)
                    } else {
                        setStaffReports([])
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    setStaffReports([])
                })
        }
    }, [token, startDate, endDate, selectedStaffs])


    const getLeadReports = useCallback(() => {
        if (token) {
            axios.post('/accounts/reports/lead_reports/', {
                staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null,
                startDate: dayjs(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                endDate: dayjs(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
            }, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then((response) => {
                    if (response && response.data) {
                        setLeadReports(response.data)
                    } else {
                        setLeadReports([])
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    setLeadReports([])
                })
        }
    }, [token, startDate, endDate, selectedStaffs])

    useEffect(() => {
        getStaffReports()
        getLeadReports()
    }, [getStaffReports, getLeadReports])

    const onExportReport = () => {
        showConfirmationToast(`Chắc chắn tải về báo cáo từ ngày ${dayjs(startDate).format('DD/MM/YYYY')} đến ngày ${dayjs(endDate).format('DD/MM/YYYY')}?`,
            () => {
                const data = staffReports.sort((a, b) => order === 'asc' ? a[orderBy] > b[orderBy] : a[orderBy] < b[orderBy])
                const worksheet = XLSX.utils.json_to_sheet(data, {origin: 'A2'});
                const headers = ['ID', "Họ và tên", 'Số Lead được giao', 'Số lead hoàn thành', 'Số đơn hàng', 'Doanh số', 'Số tương tác khách hàng', 'Hoa hồng']
                worksheet['!merges'] = [
                    { s: { r: 0, c: 0 }, e: { r: 0, c: headers.length } },
                ]
                XLSX.utils.sheet_add_aoa(worksheet, [[`Báo cáo từ ngày ${dayjs(startDate).format('DD/MM/YYYY')} đến ngày ${dayjs(endDate).format('DD/MM/YYYY')}`]], { origin: "A1" });
                XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A2" });
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
                XLSX.writeFile(workbook, `report-${dayjs(startDate).format('DD/MM/YYYY')}-${dayjs(endDate).format('DD/MM/YYYY')}.xlsx`);
            }, () => { })
    }

    const staffOptions = staffs.map((item) => ({ label: item.full_name, value: item.id }))

    if(!(authUser && hasPermission(authUser, ['view_report', 'view_report_all']))) {
        return <Stack sx={{flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px'}}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', maxWidth: '100%', width: '100%' }}>
            <ActionsBar title='Báo cáo' subTitle={`Thời gian từ ${dayjs(startDate).format('DD/MM/YYYY')} đến ${dayjs(endDate).format('DD/MM/YYYY')}`} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '24px', marginRight: '24px', flex: 1 }}>
                <Box sx={{ display: 'flex', gap: '16px', alignItems: 'enter', justifyContent: 'space-between', width: '100%' }}>
                    <Stack gap={'16px'} sx={{ alignItems: 'flex-end', width: '100%' }}>
                        <Stack gap={'8px'} direction='row' >
                            <Stack sx={{ width: '160px', cursor: 'pointer', border: '0.5px solid #cccccc', padding: '3px 5px', borderRadius: '5px' }} variant='standard' onClick={() => setDateRangeModal(true)}>
                                <InputLabel sx={{ fontSize: '10px' }}>Thời gian</InputLabel>
                                <Typography sx={{ fontSize: '12px' }}>{`${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format('DD/MM/YYYY')}`}</Typography>
                            </Stack>
                            {hasPermission(authUser, ['view_report_all']) && (
                                <Stack sx={{ width: '250px', zIndex: 20, fontSize: '12px' }}>
                                    <MultiSelect
                                        options={staffOptions}
                                        value={selectedStaffs}
                                        onChange={setSelectedStaffs}
                                        labelledBy="Chọn nhân viên"
                                        overrideStrings={{
                                            "allItemsAreSelected": "Tất cả",
                                            "clearSearch": "Xoá",
                                            "clearSelected": "Bỏ chọn",
                                            "search": "Tìm kiếm",
                                            "selectAll": "Chọn tất cả",
                                            "selectAllFiltered": "Chọn tất cả",
                                            "selectSomeItems": "Lựa chọn nhân viên"
                                        }}
                                    />
                                </Stack>
                            )}
                            {/* {
                                hasPermission(authUser, ['view_report_all']) && (
                                    <FormControl sx={{ width: '180px' }} variant='standard'>
                                        <InputLabel sx={{ fontSize: '12px' }}>Nhân viên</InputLabel>
                                        <Select sx={{ fontSize: '12px' }}
                                            multiple
                                            defaultValue={null}
                                            value={selectedStaffs}
                                            onChange={onChangeStaff} label='Nhân viên'
                                            renderValue={(selected) => (isAllSelected ? 'Tất cả' : selected.map((id) => staffOptions.find((opt) => opt.id === id)?.label).join(', '))}
                                            MenuComponent={(props) => <Menu {...props} isAllSelected={isAllSelected} />}
                                        >
                                            {
                                                staffOptions.map((option) => {
                                                    return (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            <Checkbox checked={selectedStaffs.indexOf(option.id) > -1} />
                                                            <ListItemText primary={option.label} />
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                )
                            } */}
                        </Stack>

                    </Stack>
                </Box>
            </Box>
            <Stack sx={{ marginTop: '36px', gap: '48px' }}>
                <Paper style={{ margin: '0px 24px' }}>
                    <Stack sx={{ gap: '24px', margin: '16px' }}>
                        <Typography sx={{ fontWeight: '700', fontSize: '16px', textAlign: 'center' }}>Lead khách hàng</Typography>
                        <Stack direction='row' sx={{ width: '100%' }}>
                            {leadReports.map((item) => {
                                return (
                                    <Stack sx={{ flex: 1, alignItems: 'center' }} key={item.id.toString()}>
                                        <Typography sx={{ fontSize: '30px', fontWeight: '800', }}>{item.count}</Typography>
                                        <Typography>{item.name}</Typography>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Stack>
                </Paper>

                <Paper style={{ margin: '0px 24px' }}>
                    <Stack sx={{ gap: '24px', margin: '16px' }}>
                        <Stack >
                            <Typography sx={{ fontWeight: '700', fontSize: '16px', textAlign: 'center' }}>Báo cáo theo sale</Typography>
                            <Button onClick={onExportReport} sx={{ position: 'absolute', right: '32px' }}><Download /></Button>
                        </Stack>
                        <TableContainer component={Paper} style={{ overflowY: 'scroll', width: '100%', overflowX: 'scroll', maxHeight: '60vh' }}>
                            <Table stickyHeader aria-label="simple table">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={total}
                                />
                                <TableBody>
                                    {
                                        staffReports.sort((a, b) => order === 'asc' ? a[orderBy] > b[orderBy] : a[orderBy] < b[orderBy]).map((staff) => {
                                            return (
                                                <StaffReportItem
                                                    staff={staff}
                                                />
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Paper>

            </Stack>

            <DateRangeModal
                open={dateRangeModal}
                startDate={startDate}
                endDate={endDate}
                onConfirm={onChangeRange}
                handleClose={() => {
                    setDateRangeModal(false)
                }}
            />
        </div>
    )
}

export default Screen