import React from 'react'
import { Stack, Box, Divider, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useAtom } from 'jotai'
import { authAtom } from '../../../../actions'

const ServiceSuggestItem = ({ service, service_name, staff, note, onRemove }) => {
    const [auth] = useAtom(authAtom)

    return (
        <Stack flexDirection='row'>
            <Stack style={{ flex: 1 }}>
                <Box>
                    <p>{`Tên liệu trình: ${service_name ?? (service ? service.name : '')}`}</p>
                    <p>{`Liệu trình gốc: ${service ? service.name : ''}`}</p>
                    <p>{`Người thêm: ${staff.full_name}`}</p>
                    <p>{`Ghi chú: ${note ?? ''}`}</p>
                </Box>
                <Divider />
            </Stack>
            {auth.id === staff.id &&
                <IconButton onClick={onRemove} aria-label='delete' color='error'>
                    <Delete />
                </IconButton>
            }
        </Stack>
    )
}

export default ServiceSuggestItem