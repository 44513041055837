import React, { useState } from 'react'
import { Stack, InputLabel, Autocomplete, Button, Modal, TextField, Typography, FormControl, Input } from '@mui/material'
import { convertMoney } from '../../../../utils/utils';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AddComissionModal = ({ open, onChangeComission, handleClose, order }) => {
    const { comission } = order
    const [_sale1, setSale1] = useState(comission ? comission.sale1 ?? 0 : 0)
    const [_sale2, setSale2] = useState(comission ? comission.sale2 ?? 0 : 0)
    const [_telesale, setTelesale] = useState(comission ? comission.telesale ?? 0 : 0)

    const onClose = () => {
        if (handleClose) {
            handleClose()
        }
    }

    const onAdd = () => {
        if (onChangeComission && _sale1) {
            onChangeComission({ order_id: order.id, sale1: _sale1, sale2: _sale2, telesale: _telesale })
        }
    }

    let totalPrice = 0
    let totalPaid = 0
    const serviceName = order.orders.map((item) => item.service.name).join(', ')
    let staffName = order.sale1.full_name
    if (order.sale2) staffName += `, ${order.sale2.full_name}`
    if (order.telesale) staffName += `, ${order.telesale.full_name}`
    for (const orderItem of order.orders) {
        totalPrice += orderItem.price
    }
    for (const paymentItem of order.payments ?? []) {
        totalPaid += paymentItem.amount
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack spacing={2} sx={style}>
                <h4>Hoa hồng</h4>
                <Stack >
                    <Typography sx={{ fontSize: '13px' }}>{`Đơn hàng: ${serviceName}`}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Giá trị: ${convertMoney(totalPrice, true)}`}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{`Đã thanh toán: ${convertMoney(totalPaid, true)}`}</Typography>
                </Stack>

                {
                    order.sale1 &&
                    <FormControl>
                        <InputLabel>{`Sale chính - ${order.sale1.full_name}  - ${convertMoney(_sale1, true)}`}</InputLabel>
                        <Input
                            fullWidth
                            variant="standard" value={_sale1}
                            type='number'
                            onChange={(e) => {
                                setSale1(e.target.value)
                            }}
                        />
                    </FormControl>
                }

                {
                    order.sale2 &&
                    <FormControl>
                        <InputLabel>{`Sale phụ - ${order.sale2.full_name} - ${convertMoney(_sale2, true)}`}</InputLabel>
                        <Input
                            fullWidth
                            variant="standard" value={_sale2}
                            type='number'
                            onChange={(e) => {
                                setSale2(e.target.value)
                            }}
                        />
                    </FormControl>
                }

                {
                    order.telesale &&
                    <FormControl>
                        <InputLabel>{`Telesale - ${order.telesale.full_name}  - ${convertMoney(_telesale, true)}`}</InputLabel>
                        <Input
                            fullWidth
                            variant="standard" value={_telesale}
                            type='number'
                            onChange={(e) => {
                                setTelesale(e.target.value)
                            }}
                        />
                    </FormControl>
                }

                <Stack flexDirection='row' style={{ marginTop: '48px' }} alignItems='center' justifyContent='center'>
                    <Button onClick={handleClose} color='warning' style={{ flex: 1 }}>Huỷ</Button>
                    <Button disabled={!_sale1} onClick={onAdd} color='success' style={{ flex: 1 }}>Thay đổi hoa hồng</Button>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AddComissionModal